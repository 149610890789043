import React, {useState, useEffect} from 'react';
import './table.css';
import { flexRender, useReactTable, getCoreRowModel } from "@tanstack/react-table";
import { columnDef } from './columns';

const Table = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        // Daten von PHP-Skript abrufen
        fetch('https://gpuprices.de/protected/getData.php')
        .then(response => response.json())
        .then(data => {setData(data);})
        .catch(error => {
            console.error('Fehler beim Abrufen der Daten:', error);
        });
    }, []);

    //Daten und Spaltendefinitionen werden nicht jedes mal neu gerendert
    //const finalData = React.useMemo(() => data, []);
    const finalColumnDef = React.useMemo(() => columnDef, []);

    const table = useReactTable({
        columns: finalColumnDef,
        data: data,
        getCoreRowModel: getCoreRowModel()
    });


    //Header, Body und Footer --> zuerst werden Zeilen, dann Spalten gemapt
    return (
        <table>
            <thead>
                {table.getHeaderGroups().map((header) => {
                    return(<tr key={header.id}>{header.headers.map((column) => {
                        return(<th key={column.id} colSpan={column.colSpan}>
                            {flexRender(
                                column.column.columnDef.header,
                                column.getContext()
                            )}
                        </th>)
                    })}</tr>)
                })}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => {
                    return(<tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return(<td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>)
                        })}
                    </tr>)
                })}
            </tbody>
        </table>
    )
}

export default Table;