export const columnDef = [
    {
        accessorKey: 'id',
        header: 'Id',
    },
    {
        accessorKey: 'tag',
        header: 'Grafikkarte',
    },
    {
        accessorKey: 'manufacturer',
        header: 'Hersteller',
    },
    {
        accessorKey: 'memory',
        header: 'Videospeicher',
    },
    {
        accessorKey: 'shop',
        header: 'Shop',
    },
    {
        accessorKey: 'price',
        header: 'Preis'
    },
    {
        accessorKey: 'a_link',
        header: 'Link'
    }
]