import React from 'react';
import './App.css';

import Table from './Components/Table';

function App() {
  return (
    <div className="App">
      <div className='Head'>
        <h1>Finde das beste Angebot für Deine neue Grafikkarte!</h1>
        <p>Und so einfach gehts...</p>
        <p>1. Grafikkarte aussuchen</p>
        <p>2. Der Link bringt Dich direkt zum Shop</p>
      </div>
      <div className='Body'>
        <Table/>
      </div>
      <div className='Foot'>
        <p>Impressum dies das</p>
      </div>
    </div>
  );
}

export default App;
